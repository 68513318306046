.marginTop{
  margin-top: 80px
}

.colorConviasa {
  background-color: #F9C410;
  color: black;
  text-align: center !important;
}

.colorConviasaG{
  background-color: #717171;
}
.modal-error-center{
  text-align: center !important;
}
.icon-cupon{
  text-align-last: end;
  align-self: center;
  padding: 0;
  margin: 0;
}

/* .caja{
  border-left: 3px solid #fff;
  border-right: 3px solid #fff;
} */

.paddingCaja{
  padding: 1rem;
  border-radius: 2px;
}

#primary {
  padding: 1rem;
  background-color: #F9C410;
  border-left: 3px solid #fff;
  border-right: 3px solid #fff;
  color: black;
  text-align: center;
  border-radius: 2px;
}

#secondary {
  padding: 1rem;
  background-color: #717171;
  border-left: 3px solid #fff;
  border-right: 3px solid #fff;
  color: #fff;
  text-align: center;
  border-radius: 2px
}

#expansion {
  padding: 1rem;
  background-color: #717171;
  text-align: center;
  border-radius: 2px
}

#accent {
  padding: 1rem;
  background-color: #dfdfdf;
  border-left: 3px solid #fff;
  border-right: 3px solid #fff;
  text-align: center;
  border-radius: 2px
}

#cajasClases {
  padding: 1rem;
  background-color: #868484;
  color: #fff;
  text-align: center;
  border-radius: 3px
}

.formulario input[type="radio"] {
  display: none;
}

.formulario .radio label {
  display: inline-block;
  position: relative;
  font-size: 1em;
  border-radius: 3px;
  cursor: pointer;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.formulario .radioVuelos label {
  color: #fff;
  padding: 5px 5px 5px 30px;
}

.formulario .radioTelefono label {
  color: #fff;
  padding: 5px 15px 5px 40px;
}

.formulario .radioMetodoPago label {
  color: rgb(8, 8, 8);
  padding: 5px 15px 5px 40px;
}

.formulario .radioVuelos label:hover {
  background: #F9C410;
  color: black;
}

.formulario .radioMetodoPago label:hover {
  background: #F9C410;
  color: black;
}

.formulario .radioTelefono label:hover {
  background: rgba(8, 8, 8, 0.253);
}

.formulario .radio label:before {
  content: "";
  width: 17px;
  height: 17px;
  display: inline-block;
  background: none;
  border-radius: 50%;
  position: absolute;
  left: 10px;
}

.formulario .radioVuelos label:before {
  border: 3px solid white;
}

.formulario .radioTelefono label:before {
  border: 3px solid white;
}

.formulario .radioMetodoPago label:before {
  border: 3px solid rgb(0, 0, 0);
}

.formulario .radio input[type="radio"]:checked + label {
  padding: 5px 15px;
  border-radius: 5px;
}

.formulario .radioVuelos input[type="radio"]:checked + label {
  background: #F9C410;
  color: black;
}

.formulario .radioTelefono input[type="radio"]:checked + label{
  background: rgba(15, 15, 14, 0.7);
}

.formulario .radioMetodoPago input[type="radio"]:checked + label{
  background:  #F9C410;
  color: black;
}

.formulario input[type="radio"]:checked + label::before{
  display: none;
}

input[type=number]::-webkit-outer-spin-button,

input[type=number]::-webkit-inner-spin-button {

    -webkit-appearance: none;

    margin: 0;

  }

  input[type=number] {

    -moz-appearance:textfield;

}

html {
  font-size: 1em;
  }

.cursorSenal {
  cursor: pointer;
  color: '#000000'
}

/* .btn-green {
  background: #2196f3 !important;
  color: white !important;
  font-weight: bold;
}

.colorCajasClases {
  background: #2196f3 !important;
}
*/
.footer-conviasa {
  position: fixed;
  bottom: 0;  
  width: 100%;
  height: 95px;
  color: white;
} 

.footer-conviasa2 {
  /* position: fixed; */
  bottom: 0;  
  width: 99.1%;
  height: 58px;
  color: white;
  background-color: #000000 !important;
}
@media screen and (max-width: 425px) {
  .colorConviasa {
    background-color: #F9C410;
    color: black;
    text-align: center !important;
    font-size: 11px;
  }
}
@media screen and (max-width: 375px) {
  .colorConviasa {
    background-color: #F9C410;
    color: black;
    text-align: center !important;
    font-size: 9px;
  }
}
@media screen and (max-width: 320px) {
    .colorConviasa {
      background-color: #F9C410;
      color: black;
      text-align: center !important;
      font-size: 8px;
  }
}
