.navbar {
  background-color: transparent;
  height: 80px;
  display: flex;
  justify-content: flex-center;
  align-items: center;
  padding-right: 25px;
  -webkit-transition: all 0.6s ease-out;
  -moz-transition: all 0.6s ease-out;
  -o-transition: all 0.6s ease-out;
  -ms-transition: all 0.6s ease-out;
  transition: all 0.6s ease-out;
}

.menu-bars {
  text-align: center;
}

.nav-menu {
  background-color: transparent;
  width: 350px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  right: -100%;
  transition: 850ms;
}

.nav-menu.active {
  right: 0;
  transition: 350ms;
  z-index: 51;
}

.nav-text {
  color: #000000;  
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 0px 8px 16px;
  list-style: none;
  height: 60px;
}

/* .nav-text a {
  text-decoration: none;
  color: #000000;
  font-size: 18px;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 4px;
} */

.nav-text a {
  border-bottom: 1px solid transparent; 
  color: #fff;
  transition: 0.3s;
}
.nav-text a:hover {
  border-bottom: 1px solid #F9C410;
  color: #fff;
  font-size: 14.2px;
}

.nav-menu-items {
  width: 100%;
}

.navbar-toggle {
  background-color: #000000;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

span {
  color: snow;
  font-weight: bold;
  padding: 0px;
}
.span.blaco {
  color: snow;
}

span:hover {
  color: #fff;
}

.firma-footer {
  margin-top: -100px;
}


.card-title {
  color: #000000;
}
/* media querys */
.bar-responsive {
  background-color: transparent !important;
}

@media only screen and (max-width: 2560px) {
  .logoresponsive{
    width: 300px;
    margin-left: 20px;
  }
  .nav-text{
    width: 200px;
  }
  .nav-menu{
    width: 200px;
  }
  .nav-menu-items{
    width: 1000px;
  }
  
  .navbar {
    height: 80px;
  }
  .span-menu{
    font-size: 20px;
    text-align: center;
  }
  .bar-responsive{
    font-size: 20px;
  }
  .bar-responsive2{
    font-size: 20px;
  }

  .firma-footer{
    color:snow;
    font-weight: bold;
    font-size: 10px;
  }
  .cards{
    margin-top: 50px;
    height: 600px;
    width: 300px;
  }
  .card-btn{
    width: 50%;
  }
  .card-header{
    color: "black"
  }
  .nav-menu {
    background-color: #000000;
    width: 500px;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    right: -100%;
    transition: 850ms;
    
  }
  .cine-estrenos {
    font-size: 40px;
    text-align: center;
    margin-left: 25px;
  }
  .img-poster {
    margin-left: 0px;
  }
  .boton-ficha {
    block-size: auto;
    background-color: #F9C410;
    border-color: #000000;
    color: black;
    align-items: center; 
    font-size: 9px;
  }
  .h5.modal-title {
    font-size: 18px;
    color: black;
    align-items: center; 
    font-weight: bold;
  }
  .title-sinopsis {
    text-align: center;
    color:snow;
    font-weight: 700;
    font-size: 25px;
  }
  .sinopsis {
    text-align: justify;
    color:snow;
    font-weight: 700;
    font-size: 15px;
    margin-left: 0px;
    margin-right: 0px;
  }
  .row-inf {
    margin-top: 79px;
    margin-right: 1px;
    margin-left: -40px;
    text-align: justify;
    font-size: 15px;
    font-weight: 800;
    color: snow;
  }
  
}

@media only screen and (max-width: 1600px) {

  .logoresponsive{
    width: 300px;
    margin-left: 20px;
  }
  .span-menu{
    font-size: 20px;
    text-align: center;
  }
  .nav-text{
    width: 200px;
  }
  .nav-menu{
    width: 300px;
  }
  .nav-menu-items{
    width: 1000px;
  }
  .navbar {
    height: 80px;
  }
  .bar-responsive{
    font-size: 20px;
  }
  .bar-responsive2{
    font-size: 20px;
  }

  .firma-footer{
    color:snow;
    font-weight: bold;
    font-size: 10px;
  }
  .cards{
    margin-top: 50px;
    height: 600px;
    width: 300px;
  }
  .card-btn{
    width: 50%;
  }
  .card-header{
    color: "black"
  }
  .nav-menu {
    background-color: #000000;
    width: 300px;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    right: -100%;
    transition: 850ms;
    
  }
  .cine-estrenos {
    font-size: 35px;
    text-align: center;
    margin-left: 15px;
  }
  .img-poster {
    margin-left: 0px;
  }
  .boton-ficha {
    block-size: auto;
    font-size: 20px;
    background-color: #F9C410;
    border-color: #000000;
    color: black;
    align-items: center; 
    font-size: 9px;

  }
  .title-sinopsis {
    text-align: center;
    color:snow;
    font-weight: 700;
    font-size: 25px;
  }
  .sinopsis {
    text-align: justify;
    color:snow;
    font-weight: 700;
    font-size: 15px;
    margin-left: 0px;
    margin-right: 0px;
  }
  .row-inf {
    margin-top: 79px;
    margin-right: 1px;
    margin-left: -40px;
    text-align: justify;
    font-size: 15px;
    font-weight: 800;
    color: snow;
  }
}

@media only screen and (max-width: 1024px) {
  .logoresponsive{
    width: 200px;
    margin-left: 20px;
  
  }
  
  .span-menu{
    font-size: 15px;
    text-align: center;
  }
  .nav-text{
    width: 200px;
  }
  .nav-menu{
    width: 200px;
  }
  .nav-menu-items{
    width: 1000px;
  }
  
  .navbar {
    height: 70px;
  }
  .bar-responsive{
    font-size: 15px;
  }
  .bar-responsive2{
    font-size: 15px;
  }

  .firma-footer{
    color:snow;
    font-weight: bold;
    font-size: 10px;
  }
  .cards{
    margin-top: 50px;
    height: 600px;
    width: 300px;
  }
  .card-header{
    color: black;
    text-align: center;
    vertical-align: super;
    font-size: 15px;
    color: "black"
  }
  .nav-menu {
    background-color: #000000;
    width: 300px;
    height: 100vh;
    display: flex;
    justify-content: center; 
      block-size: auto;
      font-size: 10px;
      background-color: #F9C410;
      border-color: #000000;
      color: black;
      align-items: center; 
      font-size: 11px;
      height: 30%;
      width: 100%;    font-size: 15px;

  
    }
    
  
  .cine-estrenos {
    font-size: 30px;
    text-align: center;
    margin-left: 15px;
  }
  .img-poster {
    margin-left: 0px;
  }
  .boton-ficha {
    block-size: auto;;
    height: 35% !important;
    width: 100% !important;
    background-color: #F9C410;
    border-color: #000000;
    color: black;
    align-items: center; 
    font-size: 9px;

  }
  .title-sinopsis {
    text-align: center;
    color:snow;
    font-weight: 700;
    font-size: 25px;
  }
  .sinopsis {
    text-align: justify;
    color:snow;
    font-weight: 700;
    font-size: 15px;
    margin-left: 0px;
    margin-right: 0px;
  }
  .row-inf {
    margin-top: 79px;
    margin-right: 1px;
    margin-left: -40px;
    text-align: justify;
    font-size: 15px;
    font-weight: 800;
    color: snow;
  }
}

@media only screen and (max-width: 990px) {
  .logoresponsive{
    width: 200px;
    margin-left: 20px;
  
  }
  .span-menu{
    font-size: 15px;
    text-align: center;
  }
  .nav-text{
    width: 200px;
  }
  .nav-menu{
    width: 200px;
  }
  .nav-menu-items{
    width: 1000px;
  }
  
  .navbar {
    height: 70px;
  }
  .bar-responsive{
    font-size: 15px;
  }
  .bar-responsive2{
    font-size: 15px;
  }

  .firma-footer{
    color:snow;
    font-weight: bold;
    font-size: 10px;
  }
  .cards{
    margin-top: 50px;
    height: 600px;
    width: 300px;
  }
  .card-btn{
    width: 80%;
  }
  .card-header{
    text-align: center;
    vertical-align: super;
    font-size: 15px;
  }
  .card-header{
    text-align: center;
    vertical-align: super;
    font-size: 11px;
    color: black;
  }
  .nav-menu {
    background-color: #000000;
    width: 300px;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    right: -100%;
    transition: 850ms;
    
  }
  .cine-estrenos {
    font-size: 25px;
    text-align: center;
    margin-left: 15px;
  }
  .img-poster {
    margin-left: 0px;
  }
  .boton-ficha {
    block-size: auto;
    height: 35% !important;
    width: 100% !important;
    background-color: #F9C410;
    border-color: #000000;
    color: black;
    align-items: center; 
    font-size: 9px;
  }
  .title-sinopsis {
    text-align: center;
    color:snow;
    font-weight: 700;
    font-size: 18px;
  }
  .sinopsis {
    text-align: justify;
    color:snow;
    font-weight: 700;
    font-size: 13px;
    margin-left: 0px;
    margin-right: 0px;
  }
  .span.blanco {
    font-size:14px;
    font-weight: 800;
    color:snow;
    text-align: justify;
    margin-left: 0px;
    margin-right: 0px;
  }
  .li.cont {
    text-align: justify;
    margin-left: 10px;
    margin-right: 10px;
  }
  .row-inf {
    margin-top: 68px;
    margin-right: -30px;
    margin-left: -35px;
    text-align: justify;
    font-size: 13px;
    font-weight: 800;
    color: snow;
  }
  
}

@media only screen and (max-width: 768px) {
  
  .logoresponsive{
    width: 160px;
    margin-left: 20px;
  }

  .span-menu{
    font-size: 15px;
    text-align: center;
  }
  .nav-text{
    width: 200px;
  }
  .nav-menu{
    width: 200px;
  }
  .nav-menu-items{
    width: 1000px;
  }
  
  .navbar {
    height: 70px;
  }
  .bar-responsive{
    font-size: 15px;
  }
  .bar-responsive2{
    font-size: 15px;
  }

  .firma-footer{
    color:snow;
    font-weight: bold;
    font-size: 10px;
  }
  .cards{
    margin-top: 50px;
    height: 600px;
    width: 300px;
  }
  .card-btn{
    width: 80%;
  }
  .card-header{
    text-align: center;
    vertical-align: super;
    font-size: 18px;
    color: black;
  }
  .nav-menu {
    background-color: #000000;
    width: 300px;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    right: -100%;
    transition: 850ms;
    
  }
  .cine-estrenos {
    font-size: 25px;
    text-align: center;
    margin-left: 15px;
  }
    
  .img-poster {
    margin-left: 0px;
  }
  .boton-ficha {
    block-size: auto;
    height: 25% !important;
    width: 100% !important;
    background-color: #F9C410;
    border-color: #000000;
    color: black;
    align-items: center; 
    font-size: 6px;

  }
  .title-sinopsis {
    text-align: center;
    color:snow;
    font-weight: 700;
    font-size: 18px;
  }
  .sinopsis {
    text-align: justify;
    color:snow;
    font-weight: 700;
    font-size: 13px;
    margin-left: 0px;
    margin-right: 0px;
  }
  .span.blanco {
    font-size:14px;
    font-weight: 800;
    color:snow;
    text-align: justify;
    margin-left: 0px;
    margin-right: 0px;
  }
  .li.cont {
    text-align: justify;
    margin-left: 10px;
    margin-right: 10px;
  }
  .row-inf {
    margin-top: 69px;
    margin-right: 0px;
    margin-left: -35px;
    text-align: justify;
    font-size: 13px;
    font-weight: 800;
    color: snow;
  }

}

@media only screen and (max-width: 425px) {
  .logoresponsive{
    width: 150px;
    margin-left: 20px;
  }
  .span-menu{
    font-size: 10px;
    text-align: center;
  }
  .nav-text{
    width: 200px;
  }
  .nav-menu{
    width: 200px;
  }
  .nav-menu-items{
    width: 1000px;
  }
  
  .navbar {
    height: 65px;
  }
  .bar-responsive{
    font-size: 12px;
  }

  .firma-footer{
    color:snow;
    font-weight: bold;
    font-size: 10px;
  }
  .cards{
    margin-top: 50px;
    height: 600px;
    width: 300px;
  }
  .card-btn{
    width: 80%;
  }
  .card-header{
    color: black
  }
  .nav-menu {
    background-color: #000000;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    right: -100%;
    transition: 850ms;
    
  }
  .cine-estrenos {
    font-size: 23px;
    text-align: center;
    margin-left: 15px;
  }
  .img-poster {
    margin-left: 8px;
  }
  .boton-ficha {
    block-size: auto;
    background-color: #F9C410;
    border-color: #000000;
    color: black;
    align-items: center; 
    font-size: 9px
  }
  .title-sinopsis {
    text-align: center;
    color:snow;
    font-weight: 700;
    font-size: 17px;
  }
  .sinopsis {
    text-align: justify;
    color:snow;
    font-weight: 700;
    font-size: 12px;
    margin-left: 10px;
    margin-right: 10px;
  }
  .span.blanco {
    font-size:10px;
    font-weight: 800;
    color:snow;
    text-align: justify;
    margin-left: 10px;
    margin-right: 10px;
  }
  .li.cont {
    font-size:10px;
    font-weight: 800;
    color:snow;
    text-align: justify;
    margin-left: 10px;
    margin-right: 10px;
  }
  .row-inf {
    margin-right: 1px;
    margin-left: -35px;
    text-align: justify;
    font-size: 12px
  }
}

@media only screen and (max-width: 375px) {
  .logoresponsive{
    width: 130px;
    margin-left: 20px;
  }
  .card-header{
    text-align: center;
    vertical-align: super;
    font-size: 12px;
  }
  .nav-menu {
    background-color: #000000;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    right: -100%;
    transition: 850ms;
    
  }
  .cine-estrenos {
    font-size: 20px;
    text-align: center;
    margin-left: 15px;
  }
  .img-poster {
    margin-left: 8px;
  }
  .boton-ficha {
    block-size: auto;
    font-size: 10px;
    background-color: #F9C410;
    border-color: #000000;
    color: black;
    align-items: center; 
    font-size: 9px
  }
  .title-sinopsis {
    text-align: center;
    color:snow;
    font-weight: 700;
    font-size: 16px;
  }
  .sinopsis {
    text-align: justify;
    color:snow;
    font-weight: 700;
    font-size: 10px;
    margin-left: 10px;
    margin-right: 10px;
  }
  .span.blanco {
    font-size:10px;
    font-weight: 800;
    color:snow;
    text-align: justify;
    margin-left: 10px;
    margin-right: 10px;
  }
  .li.cont {
    font-size:10px;
    font-weight: 800;
    color:snow;
    text-align: justify;
    margin-left: 10px;
    margin-right: 10px;
  }
  .row-inf {
    margin-right: 1px;
    margin-left: -35px;
    text-align: justify;
  }
}

@media only screen and (max-width: 320px) {
  .logoresponsive{
    width: 130px;
    margin-left: 20px;
  }
  .card-header{
    text-align: center;
    vertical-align: super;
    font-size: 12px;
  }
  .card-header{
    color: #000000
  }

  .nav-menu {
    background-color: #000000;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    right: -100%;
    transition: 850ms;
    
  }
  .cine-estrenos {
    font-size: 16px;
    text-align: center;
    margin-left: 15px;
  }
  .img-poster {
    margin-left: 8px;
  }
  .boton-ficha {
    block-size: auto;
    font-size: 10px;
    background-color: #F9C410;
    border-color: #000000;
    color: black;
    align-items: center; 
    font-size: 9px
  }
  .title-sinopsis {
    text-align: center;
    color:snow;
    font-weight: 700;
    font-size: 16px;
  }
  .sinopsis {
    text-align: justify;
    color:snow;
    font-weight: 700;
    font-size: 10px;
    margin-left: 10px;
    margin-right: 10px;
  }
  .span.blanco {
    font-size:10px;
    font-weight: 800;
    color:snow;
    text-align: justify;
    margin-left: 10px;
    margin-right: 10px;
  }
  .li.cont {
    font-size:10px;
    font-weight: 800;
    color:snow;
    text-align: justify;
    margin-left: 10px;
    margin-right: 10px;
  }
  .row-inf {
    margin-right: 1px;
    margin-left: -35px;
    text-align: justify;
  }
}
