
.tim-row {
  margin-bottom: 20px;
}

.tim-white-buttons {
  background-color: #777777;
}

.typography-line {
  padding-left: 25%;
  margin-bottom: 35px;
  position: relative;
  display: block;
  width: 100%;
}

.typography-line span {
  bottom: 10px;
  color: #c0c1c2;
  display: block;
  font-weight: 400;
  font-size: 13px;
  line-height: 13px;
  left: 0;
  margin-left: 20px;
  position: absolute;
  width: 260px;
  text-transform: none;
}

.tim-row {
  padding-top: 0px;
}

.tim-row h3 {
  margin-top: 0;
}

.switch {
  margin-right: 20px;
}

#navbar-full .navbar {
  border-radius: 0 !important;
  margin-bottom: 15px;
  z-index: 2;
}

#menu-dropdown .navbar {
  border-radius: 3px;
}

#pagination-row .pagination-container {
  height: 100%;
  max-height: 100%;
  display: flex;
  align-items: center;
}

/* Nucelo Icons */

#icons-row i.nc-icons {
  font-size: 30px;
}

.demo-iconshtml {
  font-size: 62.5%;
}

.demo-icons body {
  font-size: 1.6rem;
  font-family: sans-serif;
  color: #333333;
  background: white;
}

.demo-icons a {
  color: #608CEE;
  text-decoration: none;
}

.demo-icons header {
  text-align: center;
  padding: 100px 0 0;
}

.demo-icons header h1 {
  font-size: 2.8rem;
}

.demo-icons header p {
  font-size: 1.4rem;
  margin-top: 1em;
}

.demo-icons header a:hover {
  text-decoration: underline;
}

.demo-icons .nc-icon {
  font-size: 34px;
}

.demo-icons section {
  width: 90%;
  max-width: 1200px;
  margin: 50px auto;
}

.demo-icons section h2 {
  border-bottom: 1px solid #e2e2e2;
  padding: 0 0 1em .2em;
  margin-bottom: 1em;
}

.demo-icons ul::after {
  clear: both;
  content: "";
  display: table;
}

.demo-icons ul li {
  width: 20%;
  float: left;
  padding: 16px 0;
  text-align: center;
  border-radius: .25em;
  -webkit-transition: background 0.2s;
  -moz-transition: background 0.2s;
  transition: background 0.2s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: hidden;
}

.demo-icons ul li:hover {
  background: #f4f4f4;
}

.demo-icons ul p,
.demo-icons ul em,
.demo-icons ul input {
  display: inline-block;
  font-size: 1rem;
  color: #999999;
  -webkit-user-select: auto;
  -moz-user-select: auto;
  -ms-user-select: auto;
  user-select: auto;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

.demo-icons ul p {
  padding: 20px 0 0;
  font-size: 12px;
  margin: 0;
}

.demo-icons ul p::selection,
.demo-icons ul em::selection {
  background: #608CEE;
  color: #efefef;
}

.demo-icons ul em {
  font-size: 12px;
}

.demo-icons ul em::before {
  content: '[';
}

.demo-icons ul em::after {
  content: ']';
}

.demo-icons ul input {
  text-align: center;
  background: transparent;
  border: none;
  box-shadow: none;
  outline: none;
  display: none;
}

.space {
  height: 130px;
  display: block;
}

.space-110 {
  height: 110px;
  display: block;
}

.space-50 {
  height: 50px;
  display: block;
}

.space-70 {
  height: 70px;
  display: block;
}

.navigation-example {
  background-attachment: scroll;
}

.navigation-example {
  background-position: center center;
  background-size: cover;
  margin-top: 0;
  height: 100%;
}

#notifications {
  background-color: #FFFFFF;
  display: block;
  width: 100%;
  position: relative;
}

.tim-note {
  text-transform: capitalize;
}

.download-area {
  margin-top: 30px;
}

.sharing-area {
  margin-top: 50px;
}

.sharing-area .btn {
  margin-top: 14px;
}

.upgrade-pro .btn {
  margin-top: 30px;
}

#buttons .btn,
#javascriptComponents .btn {
  margin: 0 0px 10px;
}

.space-100 {
  height: 100px;
  display: block;
  width: 100%;
}

.be-social {
  padding-bottom: 20px;
  /*     border-bottom: 1px solid #aaa; */
  margin: 0 auto 40px;
}

.txt-white {
  color: #FFFFFF;
}

.txt-gray {
  color: #ddd !important;
}

.parallax {
  width: 100%;
  height: 570px;
  display: block;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.profile-content {
  padding-top: 0;
  position: relative;
  z-index: 2;
}

.logo-container .logo {
  overflow: hidden;
  border-radius: 50%;
  border: 1px solid #333333;
  width: 50px;
  float: left;
}

.logo-container .brand {
  font-size: 16px;
  color: #FFFFFF;
  line-height: 18px;
  float: left;
  margin-left: 10px;
  margin-top: 7px;
  width: 70px;
  height: 40px;
  text-align: left;
}
.preciointerna {
  text-align: center;
}

.logo-container .brand-material {
  font-size: 18px;
  margin-top: 15px;
  height: 25px;
  width: auto;
}

.logo-container .logo img {
  width: 100%;
}

.navbar-small .logo-container .brand {
  color: #333333;
}

.fixed-section {
  top: 90px;
  max-height: 80vh;
  overflow: scroll;
  position: sticky;
}

.fixed-section ul {
  padding: 0;
}

.fixed-section ul li {
  list-style: none;
}

.fixed-section li a {
  font-size: 14px;
  padding: 2px;
  display: block;
  color: #666666;
}

.fixed-section li a.active {
  color: #00bbff;
}

.fixed-section.float {
  position: fixed;
  top: 100px;
  width: 200px;
  margin-top: 0;
}

.parallax .parallax-image {
  width: 100%;
  overflow: hidden;
  position: absolute;
}

.parallax .parallax-image img {
  width: 100%;
}
.span-pagos-opciones {
  margin-right: 50px;
  font-size: 15px;
  font-weight: bold;
  color: #0a0a0a;
}
.strong-subtitle {
  place-content: center;
  color: #0a0a0a;
  font-weight: bold;
  font-size: 20px;
}

@media (max-width: 768px) {
  .parallax .parallax-image {
    width: 100%;
    height: 640px;
    overflow: hidden;
  }
  .parallax .parallax-image img {
    height: 100%;
    width: auto;
  }
}

/*.separator{
    content: "Separator";
    color: #FFFFFF;
    display: block;
    width: 100%;
    padding: 20px;
}
.separator-line{
    background-color: #EEE;
    height: 1px;
    width: 100%;
    display: block;
}
.separator.separator-gray{
    background-color: #EEEEEE;
}*/
.footer .footer-nav ul {
  color: snow !important;
}

.social-buttons-demo .btn {
  margin-right: 5px;
  margin-bottom: 7px;
}

.img-container {
  width: 100%;
  overflow: hidden;
}

.img-container img {
  width: 100%;
}

.lightbox img {
  width: 100%;
}

.lightbox .modal-content {
  overflow: hidden;
}

.lightbox .modal-body {
  padding: 0;
}

@media screen and (min-width: 991px) {
  .lightbox .modal-dialog {
    width: 960px;
  }
}

@media (max-width: 991px) {
  .fixed-section.affix {
    position: relative;
    margin-bottom: 100px;
  }
}

@media (max-width: 768px) {
  .btn,
  .btn-morphing {
    margin-bottom: 10px;
  }
  .parallax .motto {
    top: 170px;
    margin-top: 0;
    font-size: 60px;
    width: 270px;
  }
}

/*       Loading dots  */

/*      transitions */

.presentation .front,
.presentation .front:after,
.presentation .front .btn,
.logo-container .logo,
.logo-container .brand {
  -webkit-transition: all .2s;
  -moz-transition: all .2s;
  -o-transition: all .2s;
  transition: all .2s;
}

#images h4 {
  margin-bottom: 30px;
}

#javascriptComponents {
  padding-bottom: 0;
}

/*      layer animation          */

.layers-container {
  display: block;
  margin-top: 50px;
  position: relative;
}

.layers-container img {
  position: absolute;
  width: 100%;
  height: auto;
  top: 0;
  left: 0;
  text-align: center;
}

.animate {
  transition: 1.5s ease-in-out;
  -moz-transition: 1.5s ease-in-out;
  -webkit-transition: 1.5s ease-in-out;
}

.navbar-default.navbar-small .logo-container .brand {
  color: #333333;
}

.navbar-transparent.navbar-small .logo-container .brand {
  color: #FFFFFF;
}

.navbar-default.navbar-small .logo-container .brand {
  color: #333333;
}

.sharing-area {
  margin-top: 80px;
}

.sharing-area .btn {
  margin: 15px 4px 0;
}

.section-thin,
.section-notifications {
  padding: 0;
}

.section-navbars {
  padding-top: 0;
}

.section-tabs {
  background: #EEEEEE;
}

.section-pagination {
  padding-bottom: 0;
}

.section-download {
  padding-top: 130px;
}

.section-download .description {
  margin-bottom: 60px;
}

.section-download h4 {
  margin-bottom: 25px;
}

.section-examples a {
  text-decoration: none;
}

.section-examples a+a {
  margin-top: 30px;
}

.section-examples h5 {
  margin-top: 30px;
}

.components-page .wrapper>.header,
.tutorial-page .wrapper>.header {
  height: 500px;
  padding-top: 128px;
  background-size: cover;
  background-position: center center;
}

.components-page .title,
.tutorial-page .title {
  color: #FFFFFF;
}

.brand .h1-seo {
  font-size: 2.8em;
  text-transform: uppercase;
  font-weight: 300;
}

.brand .n-logo {
  max-width: 100px;
  margin-bottom: 40px;
}

.invision-logo {
  max-width: 70px;
  top: -2px;
  position: relative;
}

.creative-tim-logo {
  max-width: 140px;
  top: -2px;
  position: relative;
}

.section-javascript .title {
  margin-bottom: 0;
}

.navbar .switch-background {
  display: block;
}

.navbar-transparent .switch-background {
  display: none;
}

.section-signup .col .btn {
  margin-top: 30px;
}

#buttons-row .btn {
  margin-bottom: 10px;
}

.section-basic {
  padding-top: 0;
}

.section-images {
  padding-bottom: 0;
}

@media only screen and (min-width: 1024px) {
  .demo-icons ul li {
    width: 12.5%;
    float: left;
    padding: 32px 0; } 
  }

@media (max-width: 1200px) {
  .presentation-page .presentation-title {
    font-size: 7em; }
  .presentation-page .title-brand {
    max-width: 650px; } 
  }

@media (max-width: 1200px) {
  .presentation .section-images .image img {
    width: 100%; } 
  }

@media screen and (max-width: 374px) {
  .footer.footer-big .copyright .pull-left, .footer.footer-big .copyright .pull-right {
    float: none !important; }
  .full-screen.login .card.card-register {
    margin-bottom: 20px; }
  .full-screen.login .demo-footer {
    margin-left: -15px; }
  .about-us .header-wrapper {
    height: auto !important; }
  .presentation-page .section-content .image-container .area-img {
    max-width: 170px !important; } 
  }

@media (min-width: 1440px) {
    .logoresponsive {
      width: 30%;
    } 
    .buttonsCaramels {
      font-size: 30px;
      position: absolute;
      border-radius: 50px; 
      background-color: rgb(139,25,145); 
      border-color: rgb(139,25,145);
      width: 14%;
      margin-left: 5%;
      margin-top: 22%;
    }

    .pCaramels {
      font-size: 50px; 
      color:black;
      position: absolute;
      margin-top: -3%;
      margin-left: 5%;
      font-family: "Montserrat", "Helvetica", Arial, sans-serif;
      font-weight: bold;
    }
  }

  .hr-style-two {
    border: 0;
    height: 1px;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(39, 39, 39, 0.75), rgba(255, 255, 255, 0)); 
  }

  /* .buttonsCaramels {
    font-size: 20px;
    position: absolute;
    border-radius: 50px; 
    background-color: rgb(139,25,145); 
    border-color: rgb(139,25,145);
    width: 15%;
    margin-left: 5%;
    margin-top: 20%;
  }

  .pCaramels {
    font-size: 50px; 
    color:black;
    position: absolute;
    margin-top: -5%;
    margin-left: 5%;
  } */
  .btn-secondary {
    color: #fff;
     background-color:#F9C410 !important; 
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type=number] {
    -moz-appearance: textfield;
  }

  body {
    background-color: #0a0a0a;
    margin: 0%;
    padding: 0%;
    position: relative;
  }

  ul {
    list-style-type: none;
}
  .precio {
    text-align: center;
    color: #F9C410;
    font-weight: bold;
  }
  .cine-estrenos {
    text-align: center;
    color: #F9C410;
    font-weight: bold;
  }
  .card-title {
    text-align: center;
    color: #F9C410;
    font-weight: bold;
  }
  .cine-estrenos {
    width: '100%';
    margin-top: '50px';
  }

  .collage1 {
    width: 50%;
    background-color: #0a0a0a;
  }
  .collage2 {
    width: 50%;
  }

  .test-collage {
    -webkit-box-shadow: inset -29px 10px 44px -6px rgba(0,0,0,.2);
    -moz-box-shadow: inset -29px 10px 44px -6px rgba(0,0,0,.2);
    box-shadow: inset -29px 10px 44px -6px rgba(0,0,0,.2); 
    z-index: 20;
    font-weight: bold;
  }

  .collage-black {
    width: 100%;
    height: 100%;
    /* z-index: 2; */
  }
  .collage-img {
    width: 100%;
    height: 100%;
  }

  .contenedor {
    position: relative;
    display: inline-block;
    text-align: center;
    width: 100%;
  }

  .text-in-black {
    position: absolute;
    top: 10px;
    left: 10px;
  }
  absolute
  .text-black-centered {
    width: 100%;
    position: absolute;
    margin-top: 70%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: bold;
  }

  .black-styled-text {
    margin-top: 200px;
    color: #fff;
    font-weight: bold;
    font-size: 20px;
  }

  @media screen and (max-width: 3000px) { 
    .black-styled-text {
      font-size: 35px;
    }
    .modal-title {
      margin-bottom: 0;
      line-height: 1.5;
      font-weight: bold;
    }
    .modal-1 {
      font-size: 13px;
      color: black;
      align-items: center; 
      font-weight: 400;
      text-align: center;
    }
    .boton-modal-1-aceptar {
      block-size: auto;
      font-size: 30px;
      background-color: #0f8f44 !important;
      border-color: #0f8f44 !important;
      color: snow;
      border-radius: 0px;
      align-items: center; 
      text-align: center;
      font-size: 11px;
    }
    .boton-modal-1-cancelar {
      block-size: auto;
      font-size: 30px;
      background-color: #b90909 !important;
      border-color:#b90909 !important;
      border-radius: 0px;
      align-items: center; 
      text-align: center;
      font-size: 11px;
    }
    .label-modal-1 {
      font-size: 15px;
      color: black;
      align-items: center; 
      font-weight: bold;
      text-align: center;
    }
    .input-modal-1 {
      font-size: px;
      color: black;
      align-items: left; 
      font-weight: bold;
      text-align: center;
    }
    .modal-1-footer {
      justify-content: center;
    }
    .label-modal2 {
      font-size: 20px;
      font-weight: bold;
      color: #0a0a0a;
    }
    .footer {
      margin-top: 17%;
    }
    
  }
  @media screen and (max-width: 2560px) { 
    .black-styled-text {
      font-size: 35px;
    }
    .modal-title {
      margin-bottom: 0;
      line-height: 1.5;
      font-weight: bold;
    }
    .modal-1 {
      font-size: 13px;
      color: black;
      align-items: center; 
      font-weight: 400;
      text-align: center;
    }
    .boton-modal-1-aceptar {
      block-size: auto;
      font-size: 30px;
      background-color: #0f8f44 !important;
      border-color: #0f8f44 !important;
      color: snow;
      border-radius: 0px;
      align-items: center; 
      text-align: center;
      font-size: 11px;
    }
    .boton-modal-1-cancelar {
      block-size: auto;
      font-size: 30px;
      background-color: #b90909 !important;
      border-color:#b90909 !important;
      border-radius: 0px;
      align-items: center; 
      text-align: center;
      font-size: 11px;
    }
    .label-modal-1 {
      font-size: 15px;
      color: black;
      align-items: center; 
      font-weight: bold;
      text-align: center;
    }
    .input-modal-1 {
      font-size: px;
      color: black;
      align-items: left; 
      font-weight: bold;
      text-align: center;
    }
    .modal-1-footer {
      justify-content: center;
    }
    .label-modal2 {
      font-size: 20px;
      font-weight: bold;
      color: #0a0a0a;
    }
    .footer {
      margin-top: 17%;
    }
    
  }
  @media screen and (max-width: 2200px) { 
    .black-styled-text {
      font-size: 35px;
    }
    .modal-title {
      margin-bottom: 0;
      line-height: 1.5;
      font-weight: bold;
    }
    .modal-1 {
      font-size: 13px;
      color: black;
      align-items: center; 
      font-weight: 400;
      text-align: center;
    }
    .boton-modal-1-aceptar {
      block-size: auto;
      font-size: 30px;
      background-color: #0f8f44 !important;
      border-color: #0f8f44 !important;
      color: snow;
      border-radius: 0px;
      align-items: center; 
      text-align: center;
      font-size: 11px;
    }
    .boton-modal-1-cancelar {
      block-size: auto;
      font-size: 30px;
      background-color: #b90909 !important;
      border-color:#b90909 !important;
      border-radius: 0px;
      align-items: center; 
      text-align: center;
      font-size: 11px;
    }
    .label-modal-1 {
      font-size: 15px;
      color: black;
      align-items: center; 
      font-weight: bold;
      text-align: center;
    }
    .input-modal-1 {
      font-size: px;
      color: black;
      align-items: left; 
      font-weight: bold;
      text-align: center;
    }
    .modal-1-footer {
      justify-content: center;
    }
    .label-modal2 {
      font-size: 20px;
      font-weight: bold;
      color: #0a0a0a;
    }
    .footer {
      margin-top: 9%;
    }
    
  }
  @media screen and (max-width: 2000px) { 
    .black-styled-text {
      font-size: 35px;
    }
    .modal-title {
      margin-bottom: 0;
      line-height: 1.5;
      font-weight: bold;
    }
    .modal-1 {
      font-size: 13px;
      color: black;
      align-items: center; 
      font-weight: 400;
      text-align: center;
    }
    .boton-modal-1-aceptar {
      block-size: auto;
      font-size: 30px;
      background-color: #0f8f44 !important;
      border-color: #0f8f44 !important;
      color: snow;
      border-radius: 0px;
      align-items: center; 
      text-align: center;
      font-size: 11px;
    }
    .boton-modal-1-cancelar {
      block-size: auto;
      font-size: 30px;
      background-color: #b90909 !important;
      border-color:#b90909 !important;
      border-radius: 0px;
      align-items: center; 
      text-align: center;
      font-size: 11px;
    }
    .label-modal-1 {
      font-size: 15px;
      color: black;
      align-items: center; 
      font-weight: bold;
      text-align: center;
    }
    .input-modal-1 {
      font-size: px;
      color: black;
      align-items: left; 
      font-weight: bold;
      text-align: center;
    }
    .modal-1-footer {
      justify-content: center;
    }
    .label-modal2 {
      font-size: 20px;
      font-weight: bold;
      color: #0a0a0a;
    }
    .footer {
      margin-top: 5%;
    }
    
  }
  @media screen and (max-width: 1800px) { 
    .black-styled-text {
      font-size: 35px;
    }
    .modal-title {
      margin-bottom: 0;
      line-height: 1.5;
      font-weight: bold;
    }
    .modal-1 {
      font-size: 13px;
      color: black;
      align-items: center; 
      font-weight: 400;
      text-align: center;
    }
    .boton-modal-1-aceptar {
      block-size: auto;
      font-size: 30px;
      background-color: #0f8f44 !important;
      border-color: #0f8f44 !important;
      color: snow;
      border-radius: 0px;
      align-items: center; 
      text-align: center;
      font-size: 11px;
    }
    .boton-modal-1-cancelar {
      block-size: auto;
      font-size: 30px;
      background-color: #b90909 !important;
      border-color:#b90909 !important;
      border-radius: 0px;
      align-items: center; 
      text-align: center;
      font-size: 11px;
    }
    .label-modal-1 {
      font-size: 15px;
      color: black;
      align-items: center; 
      font-weight: bold;
      text-align: center;
    }
    .input-modal-1 {
      font-size: px;
      color: black;
      align-items: left; 
      font-weight: bold;
      text-align: center;
    }
    .modal-1-footer {
      justify-content: center;
    }
    .label-modal2 {
      font-size: 20px;
      font-weight: bold;
      color: #0a0a0a;
    }
    .footer {
      margin-top: 0% !important;
    }
  }
  @media screen and (max-width: 1600px) { 
    .black-styled-text {
      font-size: 35px;
    }
    .modal-title {
      margin-bottom: 0;
      line-height: 1.5;
      font-weight: bold;
    }
    .modal-1 {
      font-size: 13px;
      color: black;
      align-items: center; 
      font-weight: 400;
      text-align: center;
    }
    .boton-modal-1-aceptar {
      block-size: auto;
      font-size: 30px;
      background-color: #0f8f44 !important;
      border-color: #0f8f44 !important;
      color: snow;
      border-radius: 0px;
      align-items: center; 
      text-align: center;
      font-size: 11px;
    }
    .boton-modal-1-cancelar {
      block-size: auto;
      font-size: 30px;
      background-color: #b90909 !important;
      border-color:#b90909 !important;
      border-radius: 0px;
      align-items: center; 
      text-align: center;
      font-size: 11px;
    }
    .label-modal-1 {
      font-size: 15px;
      color: black;
      align-items: center; 
      font-weight: bold;
      text-align: center;
    }
    .input-modal-1 {
      font-size: px;
      color: black;
      align-items: left; 
      font-weight: bold;
      text-align: center;
    }
    .modal-1-footer {
      justify-content: center;
    }
    .label-modal2 {
      font-size: 20px;
      font-weight: bold;
      color: #0a0a0a;
    }
    .footer {
      margin-top: 0% !important;
    }
  }
  @media screen and (max-width: 1440px) { 
    .black-styled-text {
      font-size: 12px;
    }
    .label-modal2 {
      font-size: 20px;
      font-weight: bold;
      color: #0a0a0a;
    }
    .footer {
      margin-top: 0% !important;
    }
  }
  @media screen and (max-width: 1024px) { 
    .black-styled-text {
      font-size: 22px;
    }
    .modal-title {
      margin-bottom: 0;
      line-height: 1.5;
      font-weight: bold;
    }
    .modal-1 {
      margin-top: 170px;
      margin-left: 250px;
      width: 100%;
      height: 50%;
      font-size: 12px;
      color: black;
      align-items: center; 
      font-weight: 400;
      text-align: center;
    }
    .boton-modal-1-aceptar {
      block-size: auto;
      font-size: 30px;
      background-color: #0f8f44 !important;
      border-color: #0f8f44 !important;
      color: snow;
      border-radius: 0px;
      align-items: center; 
      text-align: center;
      font-size: 12px;
    }
    .boton-modal-1-cancelar {
      block-size: auto;
      font-size: 30px;
      background-color: #b90909 !important;
      border-color:#b90909 !important;
      border-radius: 0px;
      align-items: center; 
      text-align: center;
      font-size: 12px;
    }
    .label-modal-1 {
      font-size: 15px;
      color: black;
      align-items: center; 
      font-weight: bold;
      text-align: center;
    }
    .input-modal-1 {
      font-size: 12px;
      color: black;
      align-items: left; 
      font-weight: bold;
      text-align: center;
    }
    .modal-1-footer {
      justify-content: center;
    }
    .label-modal2 {
      font-size: 20px;
      font-weight: bold;
      color: #0a0a0a;
    }
    .footer {
      margin-top: 0% !important;
    }

  }
  @media screen and (max-width: 990px) {
    .black-styled-text {
      font-size: 20px;
    }
    .modal-title {
      margin-bottom: 0;
      line-height: 1.5;
      font-weight: bold;
    }
    .modal-1 {
      margin-top: 170px;
      margin-left: 260px;
      width: 60%;
      height: 60%;
      font-size: 13px;
      color: black;
      align-items: center; 
      font-weight: 400;
      text-align: center;
    }
    .boton-modal-1-aceptar {
      block-size: auto;
      font-size: 30px;
      background-color: #0f8f44 !important;
      border-color: #0f8f44 !important;
      color: snow;
      border-radius: 0px;
      align-items: center; 
      text-align: center;
      font-size: 12px;
    }
    .boton-modal-1-cancelar {
      block-size: auto;
      font-size: 30px;
      background-color: #b90909 !important;
      border-color:#b90909 !important;
      border-radius: 0px;
      align-items: center; 
      text-align: center;
      font-size: 12px;
    }
    .label-modal-1 {
      font-size: 15px;
      color: black;
      align-items: center; 
      font-weight: bold;
      text-align: center;
    }
    .input-modal-1 {
      font-size: 12px;
      color: black;
      align-items: left; 
      font-weight: bold;
      text-align: center;
    }
    .label-modal2 {
      font-size: 20px;
      font-weight: bold;
      color: #0a0a0a;
    }
    .modal-1-footer {
      justify-content: center;
    }
    .poster {
      position: absolute;
      z-index: -1;
    }
    .icono1 {
      position: absolute;
      z-index: 1;
      top: 100px;
      width: 180px;
      height: 140px;
      left: 100px;
    }
    .texto-icono1 {
      position: absolute;
      z-index: 1;
      top: 250px;
      width: 200px;
      left: 90px;
      color: snow;
      font-weight: bold;
      font-size: 30px
    }
    .icono2 {
      position: absolute;
      z-index: 1;
      top: 100px;
      width: 180px;
      height: 140px;
      left: 400px;
    }
    .texto-icono2 {
      position: absolute;
      z-index: 1;
      top: 250px;
      width: 250px;
      left: 370px;
      color: snow;
      font-weight: bold;
      font-size: 30px;
      text-align: -webkit-center;
    }
    .visa {
      position: absolute;
      z-index: 1;
      top: 120px;
      width: 110px;
      height: 100%;
      left: 440px;
    }
    .icono3 {
      position: absolute;
      z-index: 1 ;
      top: 100px;
      width: 180px;
      height: 140px;
      left: 720px;
    }
    .texto-icono3 {
      position: absolute;
      z-index: 1;
      top: 250px;
      width: 180px;
      left: 730px;
      color: snow;
      font-weight: bold;
      font-size: 30px
    }
    .footer {
      margin-top: 12% !important;
    }
   }
  @media screen and (max-width: 768px) { 
    .black-styled-text {
      font-size: 17px;
    }
    .modal-title {
      margin-bottom: 0;
      line-height: 1.5;
      font-weight: bold;
    }
    .modal-1 {
      margin-top: 170px;
      margin-left: 200px;
      width: 60%;
      height: 50%;
      font-size: 12px;
      color: black;
      align-items: center; 
      font-weight: 400;
      text-align: center;
    }
    .boton-modal-1-aceptar {
      block-size: auto;
      font-size: 30px;
      background-color: #0f8f44 !important;
      border-color: #0f8f44 !important;
      color: snow;
      border-radius: 0px;
      align-items: center; 
      text-align: center;
      font-size: 12px;
    }
    .boton-modal-1-cancelar {
      block-size: auto;
      font-size: 30px;
      background-color: #b90909 !important;
      border-color:#b90909 !important;
      border-radius: 0px;
      align-items: center; 
      text-align: center;
      font-size: 12px;
    }
    .label-modal-1 {
      font-size: 15px;
      color: black;
      align-items: center; 
      font-weight: bold;
      text-align: center;
    }
    .input-modal-1 {
      font-size: 12px;
      color: black;
      align-items: left; 
      font-weight: bold;
      text-align: center;
    }
    .modal-1-footer {
      justify-content: center;
    }
    .span-pagos-opciones {
      place-content: center;
      margin-right: 0px;
      font-size: 17px;
      font-weight: bold;
      color: #0a0a0a;
    }
    .strong-subtitle {
      place-content: center;
      color: #0a0a0a;
      font-weight: bold;
      font-size: 18px;
    }
    .label-modal2 {
      font-size: 20px;
      font-weight: bold;
      color: #0a0a0a;
    }
    .modal-2 {
      width: 86%;
      margin-left: 135px;
      text-align: center;
    }
    .icon-modal2 {
      margin-left: 0px;
      margin-top: 0px;
    }
    .footer {
      margin-top: 6% !important;
    }
  }
  @media screen and (max-width: 425px) { 
    .black-styled-text {
      font-size: 16px;
    }
    .modal-title {
      margin-bottom: 0;
      line-height: 1.5;
      font-weight: bold;
    }
    .modal-1 {
      margin-top: 150px;
      margin-left: 42px;
      width: 80%;
      height: 50%;
      font-size: 13px;
      color: black;
      align-items: center; 
      font-weight: 400;
      text-align: center;
    }
    .boton-modal-1-aceptar {
      block-size: auto;
      font-size: 30px;
      background-color: #0f8f44 !important;
      border-color: #0f8f44 !important;
      color: snow;
      border-radius: 0px;
      align-items: center; 
      text-align: center;
      font-size: 12px;
    }
    .boton-modal-1-cancelar {
      block-size: auto;
      font-size: 30px;
      background-color: #b90909 !important;
      border-color:#b90909 !important;
      border-radius: 0px;
      align-items: center; 
      text-align: center;
      font-size: 12px;
    }
    .label-modal-1 {
      font-size: 16px;
      color: black;
      align-items: center; 
      font-weight: bold;
      text-align: center;
    }
    .input-modal-1 {
      font-size: 16px;
      color: black;
      align-items: left; 
      font-weight: bold;
      text-align: center;
    }
    .modal-1-footer {
      justify-content: center;
    }
    .span-pagos-opciones {
      place-content: center;
      margin-right: 0px;
      font-size: 17px;
      font-weight: bold;
      color: #0a0a0a;
    }
    .strong-subtitle {
      place-content: center;
      color: #0a0a0a;
      font-weight: bold;
      font-size: 18px;
    }
    .label-modal2 {
      font-size: 20px ;
      font-weight: bold;
      color: #0a0a0a;
    }
    .modal-2 {
      width: 86%;
      margin-left: 30px;
      text-align: center;
    }
    .icon-modal2 {
      margin-left: 0px;
      margin-top: 0px;
      color: transparent;
    }
    .options {
      text-align: initial !important;
      width: 54% !important;
      padding: 1% !important;
    }
    .footer {
      margin-top: 10% !important;
    }
  }
  @media screen and (max-width: 375px) { 
    .black-styled-text {
      font-size: 15px;
      margin-right: 15px;
    }
    .modal-title {
      margin-bottom: 0;
      line-height: 1.5;
      font-weight: bold;
    }
    .modal-1 {
      margin-top: 150px;
      margin-left: 18px;
      width: 90%;
      height: 50%;
      font-size: 12px;
      color: black;
      align-items: center; 
      font-weight: 400;
      text-align: center;
    }
    .boton-modal-1-aceptar {
      block-size: auto;
      font-size: 30px;
      background-color: #0f8f44 !important;
      border-color: #0f8f44 !important;
      color: snow;
      border-radius: 0px;
      align-items: center; 
      text-align: center;
      font-size: 11px;
    }
    .boton-modal-1-cancelar {
      block-size: auto;
      font-size: 30px;
      background-color: #b90909 !important;
      border-color:#b90909 !important;
      border-radius: 0px;
      align-items: center; 
      text-align: center;
      font-size: 11px;
    }
    .label-modal-1 {
      font-size: 15px;
      color: black;
      align-items: center; 
      font-weight: bold;
      text-align: center;
    }
    .input-modal-1 {
      font-size: px;
      color: black;
      align-items: left; 
      font-weight: bold;
      text-align: center;
    }
    .modal-1-footer {
      justify-content: center;
    }
    .span-pagos-opciones {
      place-content: center;
      margin-right: 0px;
      font-size: 11px;
      font-weight: bold;
      color: #0a0a0a;
    }
    .strong-subtitle {
      place-content: center;
      color: #0a0a0a;
      font-weight: bold;
      font-size: 16px;
    }
    .label-modal2 {
      font-size: 16px ;
      font-weight: bold;
      color: #0a0a0a;
    }
    .modal-2 {
      width: 85%;
      margin-left: 25px;
      text-align: center;
    }
    .icon-modal2 {
      margin-left: 0px;
      margin-top: 0px;
      color: transparent;
    }
    .options {
      text-align: initial !important;
      width: 54% !important;
      padding: 1% !important;
    }
    .footer {
      margin-top: 10% !important;
    }

  }
  @media screen and (max-width: 320px) {
    .black-styled-text {
      font-size: 15px;
    }
    .modal-title {
      margin-bottom: 0;
      line-height: 1.5;
      font-weight: bold;
    }
    .modal-1 {
      margin-top: 150px;
      margin-left: 16px;
      width: 90%;
      height: 50%;
      font-size: 12px;
      color: black;
      align-items: center; 
      font-weight: 400;
      text-align: center;
    }
    .boton-modal-1-aceptar {
      block-size: auto;
      font-size: 30px;
      background-color: #0f8f44 !important;
      border-color: #0f8f44 !important;
      color: snow;
      border-radius: 0px;
      align-items: center; 
      text-align: center;
      font-size: 11px;
    }
    .boton-modal-1-cancelar {
      block-size: auto;
      font-size: 30px;
      background-color: #b90909 !important;
      border-color:#b90909 !important;
      border-radius: 0px;
      align-items: center; 
      text-align: center;
      font-size: 11px;
    }
    .label-modal-1 {
      font-size: 15px;
      color: black;
      align-items: center; 
      font-weight: bold;
      text-align: center;
    }
    .input-modal-1 {
      font-size: px;
      color: black;
      align-items: left; 
      font-weight: bold;
      text-align: center;
    }
    .modal-1-footer {
      justify-content: center;
    }
    .span-pagos-opciones {
      place-content: center;
      margin-right: 0px;
      font-size: 11px;
      font-weight: bold;
      color: #0a0a0a;
    }
    .strong-subtitle {
      place-content: center;
      color: #0a0a0a;
      font-weight: bold;
      font-size: 16px;
    }
    .label-modal2 {
      font-size: 16px ;
      font-weight: bold;
      color: #0a0a0a;
    }
    .modal-2 {
      width: 85%;
      margin-left: 25px;
      text-align: center;
    }
    .icon-modal2 {
      margin-left: 0px;
      margin-top: 0px;
      color: transparent;
      font-weight: bold;
    }
    .options {
      text-align: initial !important;
      width: 65% !important;
      padding: 1% !important;
    }
    .footer {
      margin-top: 10% !important;
    }
   }